<template>
  <div class="w-100 m-0">
    <BCard>
      <div class="py-1">
        <BCardTitle class="font-weight-bold">
          <h1>Auditoria de Contratos</h1>
        </BCardTitle>
      </div>

      <div class="mt-1">
        <!-- Filtros -->
        <BRow>
          <BCol md="2" sm="12">
            <b-form-group label="Pesquisar Por" label-for="formTypeSearch">
              <BFormSelect id="formTypeSearch" placeholder="Pesquisar Por" v-model="typeSearch"
                :options="optionsTypeSearch" @keyup.enter="search" />
            </b-form-group>
          </BCol>

          <BCol md="2" sm="12">
            <b-form-group label="Status da Auditoria" label-for="formStatusSearch">
              <BFormSelect id="formStatusSearch" placeholder="Status da Auditoria" v-model="auditStatusSearch"
                :options="optionsStatusSearch" @keyup.enter="search" />
            </b-form-group>
          </BCol>

          <BCol md="4" sm="12">
            <b-form-group label="Descrição" label-for="formDescriptionSearch">
              <BFormInput id="formDescriptionSearch" placeholder="Descrição" v-model="descriptionSearch"
                @keyup.enter="search" />
            </b-form-group>
          </BCol>

          <!-- Button Submit -->
          <BCol md="2" sm="12" class="mt-auto" style="padding-bottom: 1px">
            <b-form-group label="" label-for="buttonSubmit">
              <BButton id="buttonSubmit" type="submit" variant="primary" block @click="search"> Pesquisar </BButton>
            </b-form-group>
          </BCol>
        </BRow>
      </div>
    </BCard>

    <!-- Tabela -->
    <BCard>
      <BRow>
        <BTable id="table-audit-contract" striped :items="items" :fields="fields" :current-page="currentPage"
          :per-page="perPage" :per-page-options="perPageOptions" :total-rows="totalRows" :busy="isBusy">
          <template v-slot:table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle mr-1"></b-spinner>
              <strong>Carregando...</strong>
            </div>
          </template>

          <template #empty>
            <div class="text-center">Nenhum registro encontrado</div>
          </template>

          <template #cell(opcoes)="row">
            <BDropdown variant="link" no-caret toggle-class="p-0" left :popper-opts="{ positionFixed: true }">
              <template #button-content>
                <BButton variant="primary" class="btn-icon"
                  title="Este botão contém as opções disponíveis para este registro">
                  <feather-icon icon="SettingsIcon" />
                </BButton>
              </template>


              <BDropdownItem :disabled="!verifyCanAudit()"
                v-b-tooltip.hover="!verifyCanAudit() ? 'Sem permissão para auditar o contrato' : ''"
                @click="openAudit(row.item.id)">
                <feather-icon icon="EyeIcon" size="16" />
                <span class="align-middle ml-50">Abrir Auditoria</span>
              </BDropdownItem>
            </BDropdown>
          </template>

          <template #cell(contratoAuditado)="row">
            <BBadge :variant="row.item.contratoAuditado ? 'success' : 'warning'">
              {{ row.item.contratoAuditado ? 'Auditado' : 'Pendente' }}
            </BBadge>
          </template>
        </BTable>
      </BRow>

      <BCardBody v-if="items.length > 0" class="d-flex justify-content-between flex-wrap pt-0 mt-2">
        <!-- page length -->
        <BFormGroup style="align-items: center" label="Por Página" label-cols="6" label-align="left" label-size="sm"
          label-for="sortBySelect" class="text-nowrap mb-md-0 mr-1">
          <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="perPageOptions" />
        </BFormGroup>

        <!-- pagination -->
        <BPagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" first-number
          last-number prev-class="prev-item" next-class="next-item" class="mb-0 mt-2">
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </BPagination>
      </BCardBody>
    </BCard>

    <!-- ModalViewAudit -->
    <BModal id="modal-view-audit" title="Auditoria de Contrato" size="lg" ref="modalViewAudit" hide-footer
      no-close-on-backdrop>
      <AuditoriaViewModal :contratoId="contratoId" @fecharModal="fecharModalViewAudit" />
    </BModal>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import { formatarData, formatarValor } from '@/libs/utils'
import { message } from '@/main'
import { onMounted, reactive, ref } from '@vue/composition-api'
import { jwtDecode } from 'jwt-decode'
import AuditoriaViewModal from './AuditoriaViewModal.vue'

export default {
  name: 'AuditoriaContrato',
  components: {
    AuditoriaViewModal,
  },
  setup() {
    const userData = jwtDecode(localStorage.getItem('userData')).userData
    const contratoId = ref('')
    const isBusy = ref(false)
    const modalViewAudit = ref(null)

    // Search
    const optionsTypeSearch = reactive([
      { value: 'nome', text: 'Nome' },
      { value: 'matricula', text: 'Matrícula' },
      { value: 'codigo', text: 'Nº Contrato' },
    ])
    const optionsStatusSearch = reactive([
      { value: null, text: 'Todos' },
      { value: false, text: 'Pendente' },
      { value: true, text: 'Auditado' },
    ])
    const typeSearch = ref('nome')
    const descriptionSearch = ref('')
    const auditStatusSearch = ref(null)

    // Table
    const items = ref([])
    const fields = ref([
      { key: 'opcoes', label: 'Opções' },
      { key: 'codigo', label: 'Nº do Contrato', sortable: true },
      { key: 'nome', label: 'Nome', sortable: true },
      { key: 'matricula', label: 'Matrícula', sortable: true },
      { key: 'valorComIOF', label: 'Valor do Contrato', sortable: true, formatter: formatarValor },
      { key: 'valorParcela', label: 'Valor da Parcela', sortable: true, formatter: formatarValor },
      { key: 'dtInicio', label: 'Data de Início', sortable: true, formatter: formatarData },
      { key: 'contratoAuditado', label: 'Status da Auditoria', sortable: true },
    ])

    // Paginação
    const currentPage = ref(1)
    const perPage = ref(10)
    const perPageOptions = ref([10, 20, 50, 100])
    const totalRows = ref(0)

    const search = async () => {
      try {
        const { data } = await useJwt.post('financeiro/getContractsToAudit', {
          typeSearch: typeSearch.value,
          descriptionSearch: descriptionSearch.value,
          auditStatusSearch: auditStatusSearch.value,
          institutoId: userData.institutoSelecionado,
        })

        items.value = data
        totalRows.value = data.length
      } catch (error) {
        console.error(error)
        message.error('Erro ao buscar contratos para auditoria')
      }
    }

    onMounted(async () => {
      await search()
    })

    const openAudit = (id) => {
      contratoId.value = id
      modalViewAudit.value.show()
    }

    const fecharModalViewAudit = async () => {
      modalViewAudit.value.hide()
      await search()
    }

    const verifyCanAudit = () => {
      if (userData.role == 'master') return true
      if (userData.ConfiguracoesSistemaAuditoriaAuditar) return true
    }

    search()

    return {
      userData,
      isBusy,
      contratoId,
      modalViewAudit,

      // Filtros
      optionsTypeSearch,
      optionsStatusSearch,
      typeSearch,
      descriptionSearch,
      auditStatusSearch,
      search,

      // Tabela
      items,
      fields,

      // Paginação
      currentPage,
      perPage,
      perPageOptions,
      totalRows,

      // Métodos
      onMounted,
      openAudit,
      fecharModalViewAudit,
      verifyCanAudit,
    }
  },
}
</script>

<style lang="css" scoped></style>
