<template>
  <div>
    <!-- Tomador -->
    <BCard>
      <BCardHeader class="d-flex justify-content-center">
        <h2>Dados do Tomador:</h2>
      </BCardHeader>

      <BCardBody>
        <BRow>
          <BCol sm="12" md="6">
            <BFormGroup label="Nome:">
              <BFormInput v-model="tomador.nome" readonly />
            </BFormGroup>
          </BCol>

          <BCol sm="12" md="6">
            <BFormGroup label="Nome Social:">
              <BFormInput v-model="tomador.nomeSocial" readonly />
            </BFormGroup>
          </BCol>

          <BCol sm="12" md="4">
            <BFormGroup label="CPF">
              <BFormInput v-model="tomador.cpf" readonly />
            </BFormGroup>
          </BCol>

          <BCol sm="12" md="4">
            <BFormGroup label="Data Nascimento:">
              <BFormInput v-model="tomador.dataNascimento" readonly />
            </BFormGroup>
          </BCol>

          <BCol sm="12" md="4">
            <BFormGroup label="Estado Civil:">
              <BFormInput v-model="tomador.estadoCivil" readonly />
            </BFormGroup>
          </BCol>

          <BCol sm="12" md="4">
            <BFormGroup label="Telefone:">
              <BFormInput v-model="tomador.telefone" readonly />
            </BFormGroup>
          </BCol>

          <BCol sm="12" md="4">
            <BFormGroup label="RG Nº:">
              <BFormInput v-model="tomador.rgNumero" readonly />
            </BFormGroup>
          </BCol>

          <BCol sm="12" md="4">
            <BFormGroup label="Órgão Emissor do RG:">
              <BFormInput v-model="tomador.rgOrgaoEmissor" readonly />
            </BFormGroup>
          </BCol>

          <BCol sm="12" md="4">
            <BFormGroup label="Data de Emissão do RG:">
              <BFormInput v-model="tomador.rgDataEmissao" readonly />
            </BFormGroup>
          </BCol>

          <BCol sm="12" md="8">
            <BFormGroup label="E-mail">
              <BFormInput v-model="tomador.email" readonly />
            </BFormGroup>
          </BCol>
        </BRow>
      </BCardBody>
    </BCard>

    <!-- Segurado -->
    <BCard>
      <BCardHeader class="d-flex justify-content-center">
        <h2>Dados do Segurado:</h2>
      </BCardHeader>

      <BCardBody>
        <BRow>
          <BCol sm="12" md="4">
            <BFormGroup label="Matricula:">
              <BFormInput v-model="segurado.matricula" readonly />
            </BFormGroup>
          </BCol>

          <BCol sm="12" md="4">
            <BFormGroup label="Categoria:">
              <BFormInput v-model="segurado.categoria" readonly />
            </BFormGroup>
          </BCol>

          <BCol sm="12" md="4">
            <BFormGroup label="Cargo:">
              <BFormInput v-model="segurado.cargo" readonly />
            </BFormGroup>
          </BCol>

          <BCol sm="12" md="4">
            <BFormGroup label="Remuneração:">
              <BFormInput v-model="segurado.remuneracao" readonly />
            </BFormGroup>
          </BCol>

          <BCol sm="12" md="4">
            <BFormGroup label="Margem Utilizada:">
              <BFormInput v-model="segurado.margemUtilizada" readonly />
            </BFormGroup>
          </BCol>

          <BCol sm="12" md="4">
            <BFormGroup label="Margem Disponível:">
              <BFormInput v-model="segurado.margemDisponivel" readonly />
            </BFormGroup>
          </BCol>

          <BCol sm="12" md="6">
            <BFormGroup label="Órgão:">
              <BFormInput v-model="segurado.orgao" readonly />
            </BFormGroup>
          </BCol>

          <BCol sm="12" md="6">
            <BFormGroup label="Grupo Folha:">
              <BFormInput v-model="segurado.grupoFolha" readonly />
            </BFormGroup>
          </BCol>
        </BRow>
      </BCardBody>
    </BCard>

    <!-- Dados Bancarios -->
    <BCard>
      <BCardHeader class="d-flex justify-content-center">
        <h2>Dados Bancários:</h2>
      </BCardHeader>

      <BCardBody>
        <BRow>
          <BCol sm="12" md="6">
            <BFormGroup label="Banco:">
              <BFormInput v-model="banco.banco" readonly />
            </BFormGroup>
          </BCol>

          <BCol sm="12" md="3">
            <BFormGroup label="Agência:">
              <BFormInput v-model="banco.agencia" readonly />
            </BFormGroup>
          </BCol>

          <BCol sm="12" md="3">
            <BFormGroup label="Dígito da Agência:">
              <BFormInput v-model="banco.agenciaDV" readonly />
            </BFormGroup>
          </BCol>

          <BCol sm="12" md="4">
            <BFormGroup label="Conta:">
              <BFormInput v-model="banco.conta" readonly />
            </BFormGroup>
          </BCol>

          <BCol sm="12" md="4">
            <BFormGroup label="Dígito da Conta:">
              <BFormInput v-model="banco.contaDV" readonly />
            </BFormGroup>
          </BCol>

          <BCol sm="12" md="4">
            <BFormGroup label="Operação:">
              <BFormInput v-model="banco.operacao" readonly />
            </BFormGroup>
          </BCol>

          <BCol sm="12" md="4">
            <BFormGroup label="Tipo de Conta:">
              <BFormInput v-model="banco.tipoConta" readonly />
            </BFormGroup>
          </BCol>

          <BCol sm="12" md="4">
            <BFormGroup label="Tipo de Chave Pix:">
              <BFormInput v-model="banco.tipoChavePix" readonly />
            </BFormGroup>
          </BCol>

          <BCol sm="12" md="4">
            <BFormGroup label="Chave Pix:">
              <BFormInput v-model="banco.chavePix" readonly />
            </BFormGroup>
          </BCol>
        </BRow>
      </BCardBody>
    </BCard>

    <!-- Contrato -->
    <BCard>
      <BCardHeader class="d-flex justify-content-center">
        <h2>Dados do Contrato:</h2>
      </BCardHeader>

      <BCardBody>
        <BRow>
          <BCol sm="12" md="6">
            <BFormGroup label="Valor Liberado:">
              <BFormInput v-model="contrato.valorEmprestimo" readonly
                v-b-tooltip.hover.bottom="'Valor liberado na conta do tomador'" />
            </BFormGroup>
          </BCol>

          <BCol sm="12" md="6">
            <BFormGroup label="Valor Financiado:">
              <BFormInput v-model="contrato.valorComIOF" readonly
                v-b-tooltip.hover.bottom="'Valor liberado + Valor total do IOF'" />
            </BFormGroup>
          </BCol>

          <BCol sm="12" md="6">
            <BFormGroup label="Saldo Devedor Inicial:">
              <BFormInput v-model="contrato.valorComFloat" readonly
                v-b-tooltip.hover.bottom="'Valor financiado + Valor total do Float'" />
            </BFormGroup>
          </BCol>

          <BCol sm="12" md="6">
            <BFormGroup label="Valor da Parcela:">
              <BFormInput v-model="contrato.valorParcela" readonly />
            </BFormGroup>
          </BCol>

          <BCol sm="12" md="6">
            <BFormGroup label="Prazo:">
              <BFormInput v-model="contrato.prazo" readonly />
            </BFormGroup>
          </BCol>

          <BCol sm="12" md="6">
            <BFormGroup label="Data de Início:">
              <BFormInput v-model="contrato.dtInicio" readonly
                v-b-tooltip.hover.bottom="'Data de vencimento da primeira parcela'" />
            </BFormGroup>
          </BCol>
        </BRow>
      </BCardBody>
    </BCard>

    <!-- Processo Virtual -->
    <BCard>
      <BCardHeader class="d-flex justify-content-center">
        <h2>Processo Virtual:</h2>
      </BCardHeader>

      <BCardBody>
        <BRow>
          <BCol sm="12" md="12">
            <BTable id="table-processo-virtual" striped responsive :items="itemsProcessoVirtual"
              :fields="fieldsProcessoVirtual" :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :busy="isTableBusy">
              <template v-slot:table-busy>
                <div class="text-center my-2">
                  <BSpinner class="align-middle mr-1"></BSpinner>
                  <strong>Carregando...</strong>
                </div>
              </template>

              <template #cell(nome)="row">
                <b-link :disabled="isTableBusy" href="#" @click="downloadArquivo(row.item)" title="Abrir documento">
                  {{ row.item.nome }}
                </b-link>
              </template>
            </BTable>

            <BCardBody class="d-flex justify-content-center flex-wrap pt-0">
              <BPagination first-number last-number v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                prev-class="prev-item" next-class="next-item" class="mb-0">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>

                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </BPagination>
            </BCardBody>
          </BCol>
        </BRow>
      </BCardBody>
    </BCard>

    <!-- Concluir Auditoria -->
    <BCard>
      <BCardHeader class="d-flex justify-content-center">
        <h2>Concluir Auditoria:</h2>
      </BCardHeader>

      <BCardBody>
        <BRow>
          <BCol sm="12" md="12">
            <BFormGroup label="Observações:">
              <BFormTextarea rows="3" v-model="observacoes" placeholder="Observações sobre a auditoria" :disabled="isContractAudit" />
            </BFormGroup>
          </BCol>
        </BRow>

        <BRow>
          <BCol sm="12" md="12">
            <BButton variant="primary" block class="mt-2" @click="concluirAuditoria" :disabled="isBusy"
              v-if="!isContractAudit">
              Concluir Auditoria
              <feather-icon icon="CheckIcon" size="14" />
            </BButton>
          </BCol>
        </BRow>
      </BCardBody>
    </BCard>
  </div>
</template>

<script>
import downloadFileAWS from '@/@core/utils/Download-File-AWS';
import useJwt from '@/auth/jwt/useJwt'
import { formatarData, formatarValor } from '@/libs/utils';
import { message } from '@/main'
import { onMounted, ref, getCurrentInstance } from '@vue/composition-api'
import { BButton, BFormGroup } from 'bootstrap-vue'

export default {
  name: 'AuditoriaViewModal',
  props: {
    contratoId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const instance = getCurrentInstance();

    const isBusy = ref(false)
    const isTableBusy = ref(false)


    const isContractAudit = ref(false)
    const observacoes = ref('')

    const perPage = ref(10)
    const currentPage = ref(1)
    const totalRows = ref(0)

    const sortBy = ref('id')
    const sortDesc = ref(false)
    const sortDirection = ref('asc')
    const itemsProcessoVirtual = ref([
      {
        id: null,
        nome: null,
        s3Key: null,
        origem: null,
      },
    ])
    const fieldsProcessoVirtual = [
      { key: 'nome', label: 'Nome', sortable: false },
      { key: 'origem', label: 'Origem', sortable: false },
    ]

    const tomador = ref({
      nome: null,
      nomeSocial: null,
      dataNascimento: null,
      estadoCivil: null,
      cpf: null,
      email: null,
      telefone: null,
      rgNumero: null,
      rgOrgaoEmissor: null,
      rgDataEmissao: null,
    })

    const segurado = ref({
      matricula: null,
      categoria: null,
      orgao: null,
      grupoFolha: null,
      cargo: null,
      dataAdmissao: null,
      remuneracao: null,
      margemUtilizada: null,
      margemDisponivel: null,
    })

    const banco = ref({
      banco: null,
      agencia: null,
      agenciaDV: null,
      conta: null,
      contaDV: null,
      operacao: null,
      tipoConta: null,
      tipoChavePix: null,
      chavePix: null,
    })

    const contrato = ref({
      valorEmprestimo: null,
      valorComIOF: null,
      valorComFloat: null,
      valorParcela: null,
      prazo: null,
      dtInicio: null,
    })

    const search = async () => {
      try {
        isBusy.value = true

        const contratoId = Number(props.contratoId)
        const { data: dados } = await useJwt.get(`financeiro/getDataToAudit/${contratoId}`)

        const orgaoEmissorComUF = (dados.tomador.rgUF && dados.tomador.rgOrgaoEmissor) ? dados.tomador.rgOrgaoEmissor.trim() + '/' + dados.tomador.rgUF.trim() : 'Não informado'
        tomador.value = {
          nome: validaDado(dados.tomador.nomeCompleto),
          nomeSocial: validaDado(dados.tomador.nomeSocial),
          dataNascimento: validaDado(formatarData(dados.tomador.dataNascimento)),
          estadoCivil: validaDado(dados.tomador.estadoCivil),
          cpf: validaDado(dados.tomador.cpf),
          email: validaDado(dados.tomador.email),
          telefone: validaDado(dados.tomador.telefone),
          rgNumero: validaDado(dados.tomador.rgNumero),
          rgOrgaoEmissor: validaDado(orgaoEmissorComUF),
          rgDataEmissao: validaDado(formatarData(dados.tomador.rgDataEmissao)),
        }

        segurado.value = {
          matricula: validaDado(dados.segurado.matricula),
          categoria: validaDado(dados.segurado.situacaoPrevidenciaria),
          orgao: validaDado(dados.segurado.orgao.nome),
          grupoFolha: validaDado(dados.segurado.grupoFolha.nomeDoGrupo),
          cargo: validaDado(dados.segurado.cargo),
          dataAdmissao: validaDado(formatarData(dados.segurado.dataAdmissao)),
          remuneracao: validaDado(formatarValor(dados.segurado.remuneracao)),
          margemUtilizada: validaDado(formatarValor(dados.segurado.margemUtilizada)),
          margemDisponivel: validaDado(formatarValor(dados.segurado.margemDisponivel)),
        }

        const tipoDeConta = dados.tomador.dadoBancarioFormaDePagamento == 'creditoEmContaPoupanca' ? 'Conta Poupança' : 'Conta Corrente'
        banco.value = {
          banco: validaDado(dados.banco.nome),
          agencia: validaDado(dados.tomador.dadoBancarioAgencia),
          agenciaDV: validaDado(dados.tomador.dadoBancarioAgenciaDV),
          conta: validaDado(dados.tomador.dadoBancarioConta),
          contaDV: validaDado(dados.tomador.dadoBancarioContaDV),
          operacao: validaDado(dados.tomador.dadoBancarioContaOP),
          tipoConta: tipoDeConta,
          tipoChavePix: validaDado(dados.tomador.tipoChavePix),
          chavePix: validaDado(dados.tomador.chavePix),
        }

        contrato.value = {
          valorEmprestimo: validaDado(formatarValor(dados.contrato.valorBaseCalculo)),
          valorComIOF: validaDado(formatarValor(dados.contrato.valorComIOF)),
          valorComFloat: validaDado(formatarValor(dados.contrato.valorContrato)),
          valorParcela: validaDado(formatarValor(dados.contrato.valorParcela)),
          prazo: validaDado(dados.contrato.numeroParcelas),
          dtInicio: validaDado(formatarData(dados.contrato.dtInicio)),
        }

        isContractAudit.value = dados.auditoria.auditado
        observacoes.value = dados.auditoria.observacao


        const { data: processoVirtual } = await useJwt.get(`financeiro/carregarProcessoVirtual/${contratoId}`)
        if (processoVirtual.length)
          itemsProcessoVirtual.value = processoVirtual.map((item) => {
            const filename = item.originalname.length > 30 ? item.originalname.substring(0, 30) + '...' : item.originalname

            return {
              id: item.id,
              nome: filename,
              s3Key: item.s3Key,
              origem: item.origemDocumento,
            }
          })
        totalRows.value = processoVirtual.length
      } catch (error) {
        console.error(error)
        message.error('Erro ao buscar dados do contrato para auditoria!')
      } finally {
        isBusy.value = false
      }
    }

    const validaDado = (valor) => {
      return valor ? valor : 'Não informado'
    }

    const downloadArquivo = async (item) => {
      isTableBusy.value = true

      await downloadFileAWS(item.s3Key, item.nome)
        .then((response) => {
          if (response) {
            message.success('Download realizado com sucesso!')
          }
        })
        .catch((error) => {
          console.error(error)
          message.error('Erro ao realizar o download!')
        })
        .finally(() => {
          isTableBusy.value = false
        })
    }

    const concluirAuditoria = async () => {
      try {
        isBusy.value = true

        const contratoId = Number(props.contratoId)
        await useJwt.patch('financeiro/concluirAuditoria', {
          financeiroId: contratoId,
          observacao: observacoes.value,
        })

        message.success('Auditoria concluída com sucesso!')
        instance.proxy.$emit('fecharModal');
      } catch (error) {
        console.error(error)
        message.error('Erro ao concluir auditoria!')
      } finally {
        isBusy.value = false
      }
    }

    onMounted(() => {
      search()
    })

    return {
      isBusy,
      isTableBusy,
      search,
      downloadArquivo,
      concluirAuditoria,
      isContractAudit,

      tomador,
      segurado,
      banco,
      contrato,
      itemsProcessoVirtual,

      perPage,
      currentPage,
      totalRows,
      sortBy,
      sortDesc,
      sortDirection,
      fieldsProcessoVirtual,

      observacoes,
    }
  },
}
</script>

<style lang="css" scoped></style>
