<script>
  import { AuditoriaContrato } from './auditoria-de-contrato/index'
  import contrato from './contrato.vue'

  export default {
    components: {
      contrato,
      AuditoriaContrato,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        acesso: {
          ConfiguracoesSistemaAuditoria: false,
        }
      }
    },
    mounted() {
      const isUserMaster = this.userData.role == 'master'
      this.acesso.ConfiguracoesSistemaAuditoria = isUserMaster ? true : this.userData.acesso.ConfiguracoesSistemaAuditoria
    },
  }
</script>

<template>
  <div>
    <b-tabs>
      <b-tab active>
        <template #title>
          <feather-icon icon="PieChartIcon" size="18" class="mr-50" />
          <span class="font-weight-bold"> Contratos </span>
        </template>
        <contrato />
      </b-tab>

      <b-tab v-if="acesso.ConfiguracoesSistemaAuditoria" >
        <template #title>
          <feather-icon icon="ListIcon" size="18" class="mr-50" />
          <span class="font-weight-bold"> Auditoria de Contratos </span>
        </template>
        <AuditoriaContrato />
      </b-tab>
    </b-tabs>
  </div>
</template>
